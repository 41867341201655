/* .Company{
    display: grid; 
    padding-left: 15%;
    background-color: antiquewhite;
    grid-template-columns: 60% 40%;
    height: 650px;
    
}

.about-us{
    font-size: 70px;
}
.block-3{
    padding-right: 20%;
}
.company-image{
    height: 100%;
} */


.about-us{
    display: flex;
    align-items: center;
    padding-left: 10%;
    font-size: 40px;
    height: 250px;
    background-color: #f4f8fd;
}
.vision h2{
    display: flex;
    width: max-content;
    margin-left: 10%;
    padding-bottom: 10px;
    font-size:22px ;
    border-bottom: solid 2px #004258;

}
.text-decs{
    padding-top: 10px;
    padding-left: 10%;
    padding-right: 10%;
    color: #212121;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.contacts-icons{
    padding-top: 10px;
    display: flex;
    width: 110px;
    gap: 5%;
    padding-left: 10%;

}
.team-story{
    padding-top: 50px;
    display: grid;
    padding-left: 10%;
    padding-right: 15%;
    align-items: center;
    grid-template-columns: 50% 50%;

}
.text-1{
    padding-left: 10%;

}
.members{
    margin-top: 50px;

    height: 150px;
    background-color: #004258;
}
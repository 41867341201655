.video{
    position:absolute;
    z-index: -1;
    width: 100%;
    height: 130vh;
    /* height: 100vh; */
    top: -55px;
    overflow: hidden;
}

video{
    width: 120%;
    /* overflow: hidden; */


}
.Container-1{
    height: 100vh;
    padding-bottom: 5%;
    overflow: hidden;

}

.description-1{
    display: flex;
    /* text-align: center; */
    color: #FFFFFF;
    margin-left: 10%;
    padding-top: 23%;
    padding-right: 25%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.760vw;
    font-weight: 900;
    line-height: 4.0vw;
    flex-direction: column;
}
.Buttons{
    display: flex;
    gap: 5%;
}
.Buttons #bbb{
    background-color:#ffffff00;
    color: white;
    border-color: #FFFFFF;
}
.Buttons #bbb:hover{
    background-color:#004258;
    color: white;
    border-color: #004258;
}

.Buttons button:hover {
    /* background-color: #5BBDB3; */
    cursor: pointer;
    transition: 0.3s all;
}

#Contact{
    border-color: #5BBDB3;
    background-color: #5BBDB3;
}

.Container-2{
    margin-left: 10%;
    height: 100%;
    /* margin-bottom: 10%; */

}
.benefits{
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10%;
    margin-right: 15%;
    /* padding-bottom: 10%; */

}
.Products{
    /* padding-top: 10%; */
    height: 100vh;

}

#videodesc{
    width: 90%;
    margin-right: 10%;
    border-radius: 10px 10px 10px 10px;
    margin-top: 10%;
    margin-bottom: 10%;

}

.benefits img{

    width: 15%;
} 

.Products-detail{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;

}
.product{
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #d7cfcf;
    border-radius: 10px 10px 10px 10px;
    width: 30%;
    overflow: hidden;
    padding: 2px;
    cursor: pointer;
}
.Container-3{
    height: 100%;
}


.descripion-benefits h1{
    font-weight: 700;
    font-size: 70px;
}
.benefit{
    padding: 3%;
    border-bottom: solid 1px black;
}

.benefit-with-image{
    padding: 5%;
    border-bottom: solid 1px black;
    border-top: solid 1px black;

}

@media (max-width: 1024px) {
    .Container-1{
        height: 1000px;
    }
    video{
        width: 1850px;
    }
    .video {
        position: absolute;
        z-index: -1;
        height: max-content;
        top: -75px;
    }
    .description-1{
        padding-top: 100%;
        font-size: 80%;
        padding-right: 5%;
        line-height: normal;
        height: max-content;
        overflow: hidden;
    }
    .Buttons {
        display: flex;
        gap: 5px;
        flex-direction: column;
        padding-right: 30%;
    }
    .descripion-benefits h1 {
        font-weight: 700;
        font-size: 250%;
    }

    .benefits {
        display: flex;
        flex-direction: column;
        gap: 10%;
        margin-right: 15%;
        /* padding-bottom: 10%; */
    }

}
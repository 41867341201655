.logo {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 5%;
    width: 90px;
}
.logo:hover{
    cursor: pointer;
}
#logo-2{
    width: 140px;
    border-left: solid rgb(0, 0, 0) 3px;
    padding-left: 5%;
}

.Header{
    /* width: 100%; */
    padding-bottom: 7px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    gap: 20%;
    align-items: center;
    padding: 10px;
    color: black;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.info{
    display: flex;
    width: 100%;
    font-family: sans-serif;
    font-size: 16px;
    gap: 9%;
    text-align: center;
    /* margin-right: 5%; */
    align-items: center;

}

.info:hover{
    cursor: pointer;
}
button {
    font-size: 17px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
}

.menu-icon div {
    width: 25px;
    height: 3px;
    background-color: black;
    transition: all 0.3s;
}

.contacts{
    display: grid;
    grid-template-columns: 90% 40%;
}
/* #contacts-3 button:hover{
    background-color: #5BBDB3;
    border-color: #5BBDB3;
    color: white;
} */
@media (max-width: 768px) {
    .Header{
        background-color: #00000000;
        box-shadow: none;
    }
    .info {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.532);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: max-height 0.3s ease, opacity 0.5s ease;
        padding-bottom: 5px;
    }
    

    .info.open {
        opacity: 1;
        display: flex;
    }

    .menu-icon {
        display: flex;
    }

    .menu-icon.open .bar1 {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .menu-icon.open .bar2 {
        opacity: 0;
    }

    .menu-icon.open .bar3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}
.Products{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20%;

    /* height: 100vh; */

}
.hidro-products-detail{
    display: flex;
    gap: 5%;  
    
}

.hidro-product {
    width: 100%; 
    height: 85vh;
    background-size: cover; 
    background-position: center; 
    border-radius: 15px 15px 15px 15px;
}
.hidro-product-text{
    padding: 5%;    

}
.descripion-benefits{
    padding-top: 10%;
}

@media (max-width: 512px) {
    .hidro-product{
        height: 44vh;
    }
    .hidro-products-detail {
        display: flex;
        gap: 15px;
        flex-direction: column;
    }
    .Products{
        height: max-content;
    }
}
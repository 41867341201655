/* .installation-products-detail{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 5%;
    row-gap: 25%;
    
}
.installation-products-detail .hidro-product{
    height: 100%;
} */
.installation-products-name{
  margin-top: 10%;
}
.Services-1{
   display: grid;
   grid-template-columns: 30% 30% 30%;
   column-gap: 5%;
   row-gap: 5%;
   align-items: center;
   grid-row-gap: 20px;
   

}
.Service-1{
    border-radius: 15px 15px 15px 15px;
    overflow: hidden;
    background-color: #edeef3;
    
}
.Service-1 img{
  mix-blend-mode:darken;

}
.descrr{
    padding: 4%;
    padding-bottom: 10%;
}

.icon{
    display: flex;
    align-items: center;
    gap: 5px;

}

.descrr button{
    padding-left: 1px;
    border: none;
}



.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button span:after {
    content: '\00bb';
    position: absolute;
    color: red;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
    border: none;
  }
  
  .button:hover span {
    padding-right: 25px;
    color: red;

  }
  
  .button:hover span:after {
    opacity: 1;
    right: 0;
  }

.installation-service-products-element{
    margin: 5% 10% 10% 10%;

}
  .installation-service-products{
    overflow: hidden;
    
  }
  .installation-products-l{
    height: 450px;
    /* background-color: rgba(203, 203, 203, 0.626); */
    overflow-x:scroll ;
    display: -webkit-inline-box;
    gap: 1%;
    transition: transform 0.3s ease-in-out;
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }
  
  .installation-products-l img{
    max-height: 270px;
  }
  
  .Service-1{
    width: 32.6%;
  }
.scroll-buttons{
  display: flex;
  justify-content: space-between;
}
  .scroll-button {
    transform: translateY(-400%);
    background-color: #dc1e34;
    color: white;
    border: none;
    padding: 20px;
    cursor: pointer;
    z-index: 1;
  }
  
  .scroll-button.left {
    left: 0;
  }
  
  .scroll-button.right {
    right: 0;
  }
  
  .scroll-button:hover {
    background-color: #dc1e34cb;
  }
.block-1 {
    height: 86vh;
    background-image:linear-gradient( to right, rgba(255, 255, 255, 0.349), rgba(134, 134, 134, 0.249)),
    url('http://asiafix-pro.com/media/uploads/main-image.webp');
    
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* text-align: center; */
    /* background-color: #f7f5fd; */
    padding-left: 10%;
    text-align:left;
    display: flex;
    padding-top:10%;

}

.Description{
    padding-right: 40%;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* font: 50px Roboto, Sans-serif; */
    font-weight: 900;
    font-size: 50px;
    color: black;

}
.Description-1{
    padding-right: 45%;
}
#bbb{
    border-radius: 6px 6px 6px 6px;
    background-color: #004258;
    color: white;
    border: solid 3px #004258;
    padding: 10px 30px ;
    transition: 0.2s;
}

#bbb:hover{
    background-color: #ffffff;
    color: black;
    cursor: pointer;
}
.ending{
    padding-left: 5%;
    background-color: #f3f3f3de;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ending .logo-icon{
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}
.ending .contacts-icons{
    width: 4%;
    padding-right: 4%;
}

@media (max-width: 1024px) {
    .block-1{
        padding: 10%;

    }
    .descriptions-1{
        padding-top: 30%;

        display: flex;
        flex-direction: column;
        gap: 10%;
    }
    .team-story{
        padding-top: 50px;
        display: flex;
        padding-left: 10%;
        padding-right: 15%;
        align-items: center;
        flex-direction: column;
        } 
    .Description{
        font-size: 180%;
        padding: 1%;
    }
    .Description-1{
        padding:1%;
        /* padding-left: 1px */

    }
    .Services{
        flex-direction: column;
    }
    .Block-2 .Service {
        width: 100%;
        height: 100%;


    }
    

    .ending .contacts-icons {
        width: 20%;
        padding-right: 4%;
    }

    
    /* .Description{
        font-size: 20px;
        padding-right: 15px;
    }
    .descriptions-1{
        padding-right: 15px;

    }
    .block-1{
        width: 92%;
    }
    .Block-2 .Service {
        width: 100%;
        height: 300px;


    }
    .Services{
        flex-direction: column;
    }
    .team-story{
    padding-top: 50px;
    display: flex;
    padding-left: 10%;
    padding-right: 15%;
    align-items: center;
    flex-direction: column;
    } */
    
    
}



.main-page-installation-system{
    /* padding: 30%; */
    height: 100vh;
    background-repeat: no-repeat;
    /* background-position: center; */
    /* background-size: cover; */
}
.description{
    padding-top: 20%;
    padding-left: 10%;
    padding-right: 25%;
    font-family: "Montserrat", sans-serif;
    color: white;
    margin-bottom: 15%;

}
image{
    height: 100vh;
}


#Contact-1{
    background-color: #dc1e34;
}
#Contact-2{
    background-color: #25607c;
    background-color: #dc1e34;

}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.438) 0%, rgba(0, 0, 0, 0) 100%); /* Adjust gradient colors and opacity here */
  }
.youtube-video{
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    

}
.videodesc{
    height: 85vh;
    border-radius:  15px 15px 15px 15px;
    overflow: hidden;

}
@media (max-width: 1024px) {
    .description {
        display: flex;
        flex-direction: column;
        padding-top: 95%;
        padding-right: 5%;
        margin-bottom: 50%;
        gap: 55px;
    }
    .description h1{
        font-size: 165%;
    }
    .videodesc{
        height: 25vh;
        border-radius:  15px 15px 15px 15px;
        overflow: hidden;
    
    }
}
.Certificates{
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 20%;


    color: #000;
    padding-right: 25%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2.760vw;
    font-weight: 900;
    line-height: 4.0vw;
    flex-direction: column;
}


.loader img{
    width: 5%;
}
.loader{
    padding-top: 15%;
    height: 100vh;
    text-align: center;
}

@media (max-width: 1024px) {
    .loader img{
        width: 30%;
    }
    .loader{
        padding-top: 60%;
        height: 100vh;
        text-align: center;
    }
}
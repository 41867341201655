.Services{
    display: flex; 
    gap: 5%;
    justify-content: center;
    max-width:max-content;
    margin-top:5% ;

    margin-bottom:10% ;
    width: 80%;
    height: 100%;
    /* display: grid;
    grid-template-columns: 30% 30% 30%; */

}

.Block-2 .Services{
    padding-left: 10%;
    padding-right: 10%;

    gap: 5%;

}

img{
    object-fit:cover;
    object-position: center;
    width: 100%;
    
}
.service-image{
    height: 63%;

}
.desc{
    padding-left: 10%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* text-align: center; */
    
    font-size: 300%;
}
.descriptions span{
    height: 70px;
    overflow: hidden;
}

.descriptions{
    display: grid;
    padding: 10px;
}
.Container-3{
    height: 100vh;
}

.Service{
    background-color:#edeef3; /* Начальное значение прозрачности */
    transition: 0.3s; 
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #edeef3;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    width: 100%;
    
    
}
.main-services .Services .Service{
    height: 650px;
}
.Service:hover{
    cursor: pointer;
}

@media (max-width: 1024px) {
    .Block-2 .Services {
        gap: 30px;
        height: 750px;
    }

    .Block-2 .Service{
        display: grid;
        grid-template-rows: 70% 30%;
        height: 100%;

    }
    .service-image{
        height: 100%;
    }
    .descriptions {
        display: grid;
        padding: 10px;
        height: 30px;
        overflow: hidden;
    }
    
}
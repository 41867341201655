.hidroisolation-product-elements{
    padding-top: 5%;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;

}
.products{
    display: flex;
    gap: 5%;
    /* flex-wrap: wrap; */
}
.hidroisolation-product-elements .Service img{
    mix-blend-mode:darken;

}
.hidroisolation-product-elements .Services{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    grid-row-gap: 30px;
    width: 100%;
    
}

.hidroisolation-product-elements .Service{
    width: 100%;
}
/* .product{
    flex-grow: 1;
    flex-basis: 200;   
} */

@media (max-width: 1024px) {
    .hidroisolation-product-elements .Services {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
.Contacts{
    height: 75vh ;
    padding-top: 5%;
    padding-right: 15%;
    padding-bottom: 10%;
    padding-left: 10%;
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 10%;
    align-items: center;
}
.contact-details img{
    width: 20px;
}
.contacts-details{
    display: flex;
    align-items: center;
    gap: 5%;
    
}
.contact-image{
    
}

@media (max-width: 1024px) {
    .Contacts{
        grid-template-columns: 100%;
        padding-bottom: 35%;
        height: max-content;
    }

}
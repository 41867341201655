/* Header.css */

.header {
  color: #000000;
  align-content: center;
  display: grid;
  grid-template-columns: 30% 70% 10% ;
  /* align-items: center; */
  /* padding: 10px 20px; */
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.15);
  height: 15vh;
  width: auto;
  padding: 0px 10% 0px 8%;
  background-color: white;
  /* padding-left: 3%; */
  /* font-family: sans-serif; */
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-size: 110%;

  
}



.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 28px;
  text-transform: uppercase;
  color: #000000; /* Text color */
}

.logo-container {
  
  display: flex;
  align-items:end;
}

.logo-icon img {
  max-width: 115px;
  max-height: max-content;
  margin: 5%;
}




a{
  text-decoration: none;
  color: #000;
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}
.user-info {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10%;
  
  /* text-decoration: underline */
}
.header .user-info a:hover{
  transition: 0.1s;
  padding-bottom: 1%;
   border-bottom: solid 3px #356780;

}

@media (max-width: 1024px) {
  .header{
    font-size: 12px;
    padding-left: 35%;
    display: grid;
    grid-template-columns: 75% 1% 30%;
    /* display: block; */
  }
  .user-info{
    visibility: hidden;
  }    
  .button-header button{
    width: 90%;
    height: 30px;
    font-size: 12px;
  }

}
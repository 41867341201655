
.installation-products-elements{
    padding: 10% 5% 10% 3%;
    /* padding-top: 10%;
    padding-left: 3%;
    padding-right: 5%; */
    height: max-content;
    background-color: #f8f8f8;
    display: grid;
    grid-template-columns: 20% 80%;
    column-gap: 5%;

}


.installation-products-list{
    display:grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-right: 10%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.installation-product{
    background-color: #edeef3;
    height: 400px;
    padding: 5%;
    border-radius: 15px 15px 15px 15px;
    display: grid;

}
.installation-product img{
    mix-blend-mode:darken;
    padding-bottom: 25%;

}

.side-bar{
    max-height: 70vh;
    background-color: white;
    padding: 5%;
    border-radius: 15px;
    border: solid 1px #edeef3;
    font: 14px sans-serif;
    font-weight: 300;
    line-height: 110%;
    transition: 0.3s;

}
.category{
    padding-top: 2%;
    padding-bottom: 2%;

}
.category:hover{
    transition: 0.1s;
    cursor: pointer;
    color: red;

}
.type-name :hover {
    transition: 0.1s;
    cursor: pointer;
    color: red;
}
@media (max-width: 1024px) {
    .installation-products-list {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-right: 5%;
        flex-wrap: wrap;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }
    .installation-product {
        height: 300px;
        padding: 5%;
        border-radius: 15px 15px 15px 15px;
        display: grid;
    }
    .installation-products-elements {
        display: flex;
        flex-direction: column;
        padding: 10% 5% 10% 5%;
        height: max-content;
        background-color: #f8f8f8;
        /* display: grid; */
        column-gap: 5%;
    }
    .installation-product img {
        mix-blend-mode: darken;
        padding-bottom: 1%;
    }
}